// Players.js
import React, { useEffect, useState } from 'react';
import {
    PlayersContainer,
    PlayerList,
    PlayerItem,
    PlayerInfo,
    PlayerActions,
    EditButton,
    DeleteButton,
    AddPlayerButton,
    BackButton,
} from './style.css'; // Adjust the import path
import { getMatches } from './utils';
import { deleteMatch } from './utils';
import { useNavigate } from 'react-router-dom';

// const dummyPlayers = [
//     { id: 1, name: 'Player 1', score: 100, rank: 'Beginner' },
//     { id: 2, name: 'Player 2', score: 200, rank: 'Intermediate' },
//     { id: 3, name: 'Player 3', score: 300, rank: 'Advanced' },
// ];

const Matches = () => {

    const navigate = useNavigate();

    const [players, setPlayers] = useState([]);

    const handleEditPlayer = (player) => {
        // Implement your edit player logic here
        // console.log(`Editing player: ${player.name}`);
        // alert("Comming soon...")
        window.location.assign(`/matches/edit/${player?._id}`)
    };

    const handleDeletePlayer = (player) => {
        // Implement your delete player logic here
        const confirmation = window.confirm(`Are you sure you want to delete match?`);
        if ( confirmation ) {
            deleteMatch(player._id)
            .then((res) => {
                if ( res.data?.code === 200 && !res.data?.error ) {
                    const updatedPlayers = players.filter((p) => p._id !== player._id);
                    setPlayers(updatedPlayers);
                } else alert(res.data.error?.details?.message ? res.data.error?.details?.message :'Something went wrong')
            })
        }
    };

    const handleAddPlayer = () => {
        navigate('/matches/add')
    };

    useEffect(() => {
        getMatches()
            .then((res) => {
                console.log('res',res);
                setPlayers(res)
            })
    },[])


    return (
        <PlayersContainer>
            <h1>Matches</h1>
            <AddPlayerButton onClick={handleAddPlayer}>Add Match</AddPlayerButton>
            <BackButton onClick={() => navigate('/home')}>Back to Home</BackButton>
            <PlayerList>
                {players?.map((player) => {
                    const options = { day: '2-digit', month: 'short', year: 'numeric' };
                    return <PlayerItem key={player._id}>
                        <PlayerInfo>
                            <h5>
                                <strong>{player?.players[0]?.playerId?.firstname} {player?.players[0]?.playerId?.lastname}</strong> Vs <strong>{player?.players[1]?.playerId?.firstname} {player?.players[1]?.playerId?.lastname}</strong> 
                            </h5>
                            <div>
                                <strong>Score:</strong> {player?.players[0]?.score} - {player?.players[1]?.score}
                            </div>
                            <div>
                                <strong>Location:</strong> {player.location?.name}
                            </div>
                            <div>
                                <strong>Date:</strong> {new Date(player.createdAt).toLocaleString('en-US', options)}
                            </div>
                        </PlayerInfo>
                        <PlayerActions>
                            <EditButton onClick={() => handleEditPlayer(player)}>Edit</EditButton>
                            <DeleteButton onClick={() => handleDeletePlayer(player)}>Delete</DeleteButton>
                        </PlayerActions>
                    </PlayerItem>
                })}
            </PlayerList>
        </PlayersContainer>
    );
};

export default Matches;
