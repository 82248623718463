import React, { useEffect, useState } from 'react';
import {
    PlayersContainer,
    PlayerList,
    PlayerItem,
    PlayerInfo,
    PlayerActions,
    EditButton,
    DeleteButton,
    AddPlayerButton,
    BackButton,
} from './style.css'; // Adjust the import path
import { deleteLocation, getLocations } from './utils';
import { useNavigate } from 'react-router-dom';

const Players = () => {
    const navigate = useNavigate();
    const [players, setPlayers] = useState([]);

    const handleEditPlayer = (player) => {
        // Implement your edit player logic here
        window.location.assign(`/locations/edit/${player?._id}`);
    };

    const handleDeletePlayer = (player) => {
        // Show a confirmation dialog before deleting
        const confirmation = window.confirm(`Are you sure you want to delete ${player.name}?`);
        if (confirmation) {
            deleteLocation(player._id)
                .then((res) => {
                    if (res.data?.code === 200 && !res.data?.error) {
                        const updatedPlayers = players.filter((p) => p._id !== player._id);
                        setPlayers(updatedPlayers);
                    } else alert(res.data.error?.details?.message ? res.data.error?.details?.message : 'Something went wrong');
                });
        }
    };

    const handleAddPlayer = () => {
        navigate('/locations/add');
    };

    useEffect(() => {
        getLocations()
            .then((res) => {
                console.log('res', res);
                setPlayers(res);
            });
    }, []);

    return (
        <PlayersContainer>
            <h1>Locations</h1>
            <AddPlayerButton onClick={handleAddPlayer}>Add Location</AddPlayerButton>
            <BackButton onClick={() => navigate('/home')}>Back to Home</BackButton>
            <PlayerList>
                {players?.map((player) => (
                    <PlayerItem key={player._id}>
                        <PlayerInfo>
                            <h3>
                                <strong>{player.name}</strong>
                            </h3>
                        </PlayerInfo>
                        <PlayerActions>
                            <EditButton onClick={() => handleEditPlayer(player)}>Edit</EditButton>
                            <DeleteButton onClick={() => handleDeletePlayer(player)}>Delete</DeleteButton>
                        </PlayerActions>
                    </PlayerItem>
                ))}
            </PlayerList>
        </PlayersContainer>
    );
};

export default Players;
