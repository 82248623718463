import axios from 'axios';
import Domains from "../../config/domains";


const backendURI = Domains.backend;


export function getMatches(){
    return new Promise((resolve, reject) => {
        axios.get(`${backendURI}/matches/getMatches`)
        .then((res) => {
            resolve(res?.data?.data?.matches)
        }).catch((err) => console.error(err))
    })
}

export function deleteMatch(matchId){
    return new Promise((resolve, reject) => {
        axios.post(`${backendURI}/matches/deleteMatch`, {matchId})
        .then((res) => {
            resolve(res)
        }).catch((err) => console.error(err))
    })
}