import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    LandingContainer,
    Nav,
    Ul,
    Li,
    LandingContent,
    Section,
} from './style.css'; // Make sure to adjust the import path
import { useAuthApi } from '../../setup/authentication/requests';

const Landing = () => {

    const authApi = useAuthApi();

    const navigate = useNavigate();
    const [logged, setLogged] = useState(false);
    const [loading, setLoading] = useState(true);

    const handleLogout = () => {
        // You can implement your logout logic here
        // For now, we'll just set logged to false
        setLogged(false);
    };

    useEffect(() => {
        const runVerify = async () => {
            try {
                const response = await authApi.verify();
                if (response?.data?.error) {
                    setLogged(false);
                } else setLogged(true)
            } catch (error) {
        
            } finally {
                setLoading(false);
            }
        };

        runVerify();
    }, []);

    useEffect(() => {
        if ( logged ) navigate('/home')
    },[logged])

    if ( loading ) return <></>
    return (
        <LandingContainer>
            <Nav>
                <Ul>
                    {logged ? (
                        <Li onClick={handleLogout}>Logout</Li>
                    ) : (
                        <>
                            <Li onClick={() => navigate('/login')}>Login</Li>
                            <Li onClick={() => navigate('/register')}>Register</Li>
                        </>
                    )}
                </Ul>
            </Nav>
            <LandingContent>
                <h1>Welcome to Billiard Club</h1>
            </LandingContent>
        </LandingContainer>
    );
};

export default Landing;
