import React from 'react';
import LoginForm from './components/Form';
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const navigate = useNavigate();
;
    return (
        <div style={{padding: "5rem"}}>
            <LoginForm />
            <p style={{marginTop: "2rem", cursor: "pointer", color: "white"}} onClick={() => navigate('/register')}>New user? Register here</p>
        </div>
    )
}

export default Login