import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    HomeContainer,
    Nav,
    Ul,
    Li,
    HomeContent,
    Section,
} from './style.css'; // Make sure to adjust the import path
import { useAuthApi } from '../../setup/authentication/requests';

const Home = () => {
    const authApi = useAuthApi();
    const navigate = useNavigate();
    const [logged, setLogged] = useState(true);

    const handleLogout = () => {
        // You can implement your logout logic here
        // For now, we'll just set logged to false
        // setLogged(false);
        authApi.logout()
            .then((res) => {
                console.log('res',res);
                if ( res.data.code === 200 && !res.data.error ) {
                    window.location.assign('/')
                } else alert('Something went wrong')
            })  
            .catch((err) => {})
    };

    return (
        <HomeContainer>
            <Nav>
                <Ul>
                    {logged ? (
                        <Li onClick={handleLogout}>Logout</Li>
                    ) : (
                        <>
                            <Li onClick={() => navigate('/login')}>Login</Li>
                            <Li onClick={() => navigate('/register')}>Register</Li>
                        </>
                    )}
                </Ul>
            </Nav>
            <HomeContent>
                <h1>Welcome to Billiard Club</h1>
                <p>Explore the sections below:</p>
                <Section style={{cursor: "pointer"}} onClick={() => navigate('/players')}>Players</Section>
                <Section style={{cursor: "pointer"}} onClick={() => navigate('/locations')}>Locations</Section>
                <Section style={{cursor: "pointer"}} onClick={() => navigate('/matches')}>Matches</Section>
                <Section style={{cursor: "pointer"}} onClick={() => navigate('/statistics')}>Statistics</Section>
            </HomeContent>
        </HomeContainer>
    );
};

export default Home;
