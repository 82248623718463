import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    PlayersContainer,
    PlayerItem,
    PlayerInfo,
    PlayerActions,
    EditButton,
    DeleteButton,
    AddPlayerButton,
    InputField,
    BackButton, // New component for input fields
} from './style.css'; // Adjust the import path
import { createPlayer, getPlayers, updatePlayer } from './utils';

const AddPlayer = () => {

    const navigate = useNavigate();
   
    const {id} = useParams();

    const [newPlayer, setNewPlayer] = useState({
        firstname: '',
        lastname: '',
        nickname: '',
        // Add more fields as needed
    });

    const handleAddPlayer = () => {
        if ( !id ) {
            createPlayer(newPlayer)
            .then((res) => {
                console.log('res',res);
                if (  !res || res.data?.error ) alert(res.data.error?.details?.message)
                else navigate('/players')
            })
        } else {
            updatePlayer(newPlayer, id)
                .then((res) => {
                    console.log('res',res);
                    if (  !res || res.data?.error ) alert(res.data.error?.details?.message)
                    else navigate('/players')
                })
        }
    };

    useEffect(() => {
        if ( id ) {
            getPlayers([id])
                .then((res) => {
                    console.log(res);
                    if ( res ) setNewPlayer({
                        ...newPlayer,
                        firstname: res[0]?.firstname,
                        lastname: res[0]?.lastname,
                        nickname: res[0]?.nickname,

                    }) 
                    else alert("Something went wrong")
                })
        }
    },[])

    return (
        <PlayersContainer>
            <h1>{id ? "Edit" : "Add New"} Player</h1>
            <PlayerItem>
                <PlayerInfo>
                    <InputField
                        type="text"
                        placeholder="Fistname"
                        value={newPlayer.firstname}
                        onChange={(e) => setNewPlayer({ ...newPlayer, firstname: e.target.value })}
                    />
                    <InputField
                        type="text"
                        placeholder="Lastname"
                        value={newPlayer.lastname}
                        onChange={(e) => setNewPlayer({ ...newPlayer, lastname: e.target.value })}
                    />
                    <InputField
                        type="text"
                        placeholder="Nickname"
                        value={newPlayer.nickname}
                        onChange={(e) => setNewPlayer({ ...newPlayer, nickname: e.target.value })}
                    />
                    {/* Add more input fields as needed */}
                </PlayerInfo>
                <PlayerActions>
                    <AddPlayerButton onClick={handleAddPlayer}>{id ? "Edit" : "Add"} Player</AddPlayerButton>
                </PlayerActions>
            </PlayerItem>
            <BackButton onClick={() => navigate('/players')}>Back to Players</BackButton>
        </PlayersContainer>
    );
};

export default AddPlayer;
