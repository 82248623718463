import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    PlayersContainer,
    PlayerItem,
    PlayerInfo,
    PlayerActions,
    EditButton,
    DeleteButton,
    AddPlayerButton,
    InputField,
    BackButton, // New component for input fields
} from './style.css'; // Adjust the import path
import './select.css'
import { createMatch, getLocations, getMatches, getPlayers, updateMatch } from './utils';
import { updateLocation } from '../addLocation/utils';



const AddMatch = () => {

    const [data, setData] = useState({
        locations: [],
        players: [],
    })

    const {id} = useParams();

    const [disabled, setDisabled] = useState({
        locationId: false,
        firstPlayerId: false,
        secondPlayerId: false,
        firstPlayerScore: false,
        secondPlayerScore: false,
    })

    const navigate = useNavigate();
    
    const [newPlayer, setNewPlayer] = useState({
        locationId: '',
        firstPlayerId: '',
        secondPlayerId: '',
        firstPlayerScore: 0,
        secondPlayerScore: 0,
        // Add more fields as needed
    });

    const handleAddPlayer = () => {

        const payload = {
            players: [
                {playerId: newPlayer.firstPlayerId, score: newPlayer.firstPlayerScore},
                {playerId: newPlayer.secondPlayerId, score: newPlayer.secondPlayerScore},
            ],
            location: newPlayer.locationId
        }

        if ( !id ) {
            createMatch(payload)
            .then((res) => {
                console.log('res',res);
                if (  !res || res.data?.error ) alert(res.data.error?.details?.message)
                else navigate('/matches')
            })
        } else {
            updateMatch(payload, id)
                .then((res) => {
                    console.log('res',res);
                    if ( !res || res.data?.error ) alert(res.data.error?.details?.message)
                    else navigate('/matches')
                })
        }
    };

    useEffect(() => {

        // Usage of Promise.all
        Promise.all([getLocations(), getPlayers()])
            .then(([locations, players]) => {
                // Here, you have both locations and players data
                console.log('Locations:', locations);
                console.log('Players:', players);
                setData({...data, locations, players})
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    },[])


    useEffect(() => {
        if ( newPlayer.locationId ) setDisabled({...disabled, locationId: true})
        if ( newPlayer.firstPlayerId ) setDisabled({...disabled, firstPlayerId: true})
        if ( newPlayer.firstPlayerScore ) setDisabled({...disabled, firstPlayerScore: true})
        if ( newPlayer.secondPlayerId ) setDisabled({...disabled, secondPlayerId: true})
        if ( newPlayer.secondPlayerScore ) setDisabled({...disabled, secondPlayerScore: true})
    },[newPlayer])



    useEffect(() => {
        if ( id ) {
            getMatches([id])
                .then((res) => {
                    console.log('sssss',res);
                    if ( res ) setNewPlayer({
                        ...newPlayer,
                        locationId: res[0]?.location?._id,
                        firstPlayerId: res[0]?.players[0]?.playerId?._id,
                        secondPlayerId: res[0]?.players[1]?.playerId?._id,
                        firstPlayerScore: res[0]?.players[0]?.score,
                        secondPlayerScore: res[0]?.players[1]?.score,

                    }) 
                    else alert("Something went wrong")
                })
        }
    },[])


    console.log('newPlayer',newPlayer);

    return (
        <PlayersContainer>
            <h1>{id ? "Edit" : "Add New"} Match</h1>
            <PlayerItem>
                <PlayerInfo>
                <select
                    value={newPlayer.locationId}
                    onChange={(e) => setNewPlayer({ ...newPlayer, locationId: e.target.value })}
                >
                        <option disabled={disabled.locationId} selected={true}>
                            Select Location
                        </option>
                    {data.locations?.map((location) => (
                        <option key={location._id} value={location._id}>
                            {location.name}
                        </option>
                    ))}
                </select>

                <select
                    value={newPlayer.firstPlayerId}
                    onChange={(e) => setNewPlayer({ ...newPlayer, firstPlayerId: e.target.value })}
                >
                        <option disabled={disabled.firstPlayerId} selected={true}>
                            Select Player One
                        </option>
                    {data.players?.map((player) => (
                        <option key={player._id} value={player._id}>
                            {player.firstname} {player.lastname}
                        </option>
                    ))}
                </select>

                <InputField 
                    style={{marginBottom: "1rem", marginTop: "0"}}
                    type="number"
                    placeholder="Player One Score"
                    value={newPlayer.firstPlayerScore}
                    onChange={(e) => setNewPlayer({ ...newPlayer, firstPlayerScore: parseInt(e.target.value) })}
                />

                <select
                    value={newPlayer.secondPlayerId}
                    onChange={(e) => setNewPlayer({ ...newPlayer, secondPlayerId: e.target.value })}
                >
                        <option disabled={disabled.secondPlayerId} selected={true}>
                            Select Player Two
                        </option>
                    {data.players?.map((player) => (
                        <option key={player._id} value={player._id}>
                            {player.firstname} {player.lastname}
                        </option>
                    ))}
                </select>

                <InputField 
                    style={{marginBottom: "1rem", marginTop: "0"}}
                    type="number"
                    placeholder="Player Two Score"
                    value={newPlayer.secondPlayerScore}
                    onChange={(e) => setNewPlayer({ ...newPlayer, secondPlayerScore: parseInt(e.target.value) })}
                />

                </PlayerInfo>
                <PlayerActions>
                    <AddPlayerButton onClick={handleAddPlayer}>{id ? "Edit" : "Add"} Match</AddPlayerButton>
                </PlayerActions>
            </PlayerItem>
            <BackButton onClick={() => navigate('/matches')}>Back to Matches</BackButton>
        </PlayersContainer>
    );
};

export default AddMatch;
