import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    PlayersContainer,
    PlayerItem,
    PlayerInfo,
    PlayerActions,
    EditButton,
    DeleteButton,
    AddPlayerButton,
    InputField,
    BackButton, // New component for input fields
} from './style.css'; // Adjust the import path
import { createLocation, getLocations, updateLocation } from './utils';

const AddLocation = () => {

    const navigate = useNavigate();

    const {id} = useParams();
    
    const [newPlayer, setNewPlayer] = useState({
        name: '',
        // Add more fields as needed
    });

    const handleAddPlayer = () => {
        if ( !id ) {
            createLocation(newPlayer)
            .then((res) => {
                console.log('res',res);
                if (  !res ||res.data?.error ) alert(res.data.error?.details?.message)
                else navigate('/locations')
            })
        } else {
            updateLocation(newPlayer, id)
                .then((res) => {
                    console.log('res',res);
                    if (  !res ||res.data?.error ) alert(res.data.error?.details?.message)
                    else navigate('/locations')
                })
        }
    };


    useEffect(() => {
        if ( id ) {
            getLocations([id])
                .then((res) => {
                    console.log(res);
                    if ( res ) setNewPlayer({
                        ...newPlayer,
                        name: res[0]?.name,
                    }) 
                    else alert("Something went wrong")
                })
        }
    },[])

    return (
        <PlayersContainer>
            <h1>{id ? "Edit" : "Add New"} Location</h1>
            <PlayerItem>
                <PlayerInfo>
                    <InputField
                        type="text"
                        placeholder="Name"
                        value={newPlayer.name}
                        onChange={(e) => setNewPlayer({ ...newPlayer, name: e.target.value })}
                    />
                    {/* Add more input fields as needed */}
                </PlayerInfo>
                <PlayerActions>
                    <AddPlayerButton onClick={handleAddPlayer}>{id ? "Edit" : "Add"} Location</AddPlayerButton>
                </PlayerActions>
            </PlayerItem>
            <BackButton onClick={() => navigate('/locations')}>Back to Locations</BackButton>
        </PlayersContainer>
    );
};

export default AddLocation;
