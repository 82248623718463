/* AddPlayer.css.js */
import styled from 'styled-components';

export const PlayersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  color: white;
`;

export const PlayerItem = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #444;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 768px) {
    width: 70%;
  }
`;

export const PlayerActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-end;
    width: 30%;
  }
`;

export const AddPlayerButton = styled.button`
  cursor: pointer;
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  margin-top: 20px;
`;

export const BackButton = styled.button`
  cursor: pointer;
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const TableContainer = styled.table`
  width: 70%;
  border-collapse: collapse;
  margin-top: 20px;
`;

export const TableHead = styled.thead`
  background-color: #444; /* Updated background color */
  color: #ffffff;
`;

export const TableTh = styled.th`
  border: 1px solid #ffffff;
  padding: 8px;
  text-align: left;
  background-color: #444; /* Updated background color */
`;

export const TableBody = styled.tbody`
    background-color: #27004b;
`;

export const TableTd = styled.td`
  border: 1px solid #444;
  padding: 8px;
  text-align: left;
`;