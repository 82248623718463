import axios from 'axios';
import Domains from "../../config/domains";


const backendURI = Domains.backend;


export function getLocations(){
    return new Promise((resolve, reject) => {
        axios.get(`${backendURI}/locations/getLocations`)
        .then((res) => {
            resolve(res?.data?.data?.locations)
        }).catch((err) => console.error(err))
    })
}


export function deleteLocation(locationId){
    return new Promise((resolve, reject) => {
        axios.post(`${backendURI}/locations/deleteLocation`, {locationId})
        .then((res) => {
            resolve(res)
        }).catch((err) => console.error(err))
    })
}