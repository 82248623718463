// Players.css.js
import styled from 'styled-components';

export const PlayersContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    color: white; /* Text color */
`;

export const PlayerList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    width: 75%; /* Set the width of the player list to 75% */

    @media (max-width: 768px) {
        width: 90%; /* Adjust the width for smaller screens */
    }
`;

export const PlayerItem = styled.li`
    display: flex;
    flex-direction: column;
    background-color: #444; /* Section background */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
        flex-direction: row; /* Change to row layout for screens wider than 768px */
        justify-content: space-between;
        align-items: center;
    }
`;

export const PlayerInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%; /* Take the full width for small screens */

    @media (min-width: 768px) {
        width: 70%; /* Set the width of the player information to 70% of the player item */
    }
`;

export const PlayerActions = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%; /* Take the full width for small screens */

    @media (min-width: 768px) {
        flex-direction: row; /* Change to row layout for screens wider than 768px */
        justify-content: flex-end; /* Align the buttons to the right */
        width: 30%; /* Set the width of the player actions to 30% of the player item */
    }
`;

export const EditButton = styled.button`
    cursor: pointer;
    background-color: #007bff; /* Edit button color */
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
`;

export const DeleteButton = styled.button`
    cursor: pointer;
    background-color: #dc3545; /* Delete button color */
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
`;

export const AddPlayerButton = styled.button`
    cursor: pointer;
    background-color: #28a745; /* Add Player button color */
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    margin-top: 20px;
`;

export const BackButton = styled.button`
    cursor: pointer;
    background-color: #007bff; /* Back button color */
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    margin-top: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
`;