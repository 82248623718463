import axios from 'axios';
import Domains from "../../config/domains";


const backendURI = Domains.backend;


export function getPlayers(){
    return new Promise((resolve, reject) => {
        axios.get(`${backendURI}/players/getPlayers`)
        .then((res) => {
            console.log(res);
            resolve(res?.data?.data?.players)
        }).catch((err) => console.error(err))
    })
}


export function deletePlayer(playerId){
    return new Promise((resolve, reject) => {
        axios.post(`${backendURI}/players/deletePlayer`, {playerId})
        .then((res) => {
            resolve(res)
        }).catch((err) => console.error(err))
    })
}