import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Guard } from "../authentication/guard";

import Login from "../../pages/auth/login";
import Register from "../../pages/auth/register";
import Landing from "../../pages/landing";
import Home from "../../pages/home";
import Players from "../../pages/players";
import Locations from "../../pages/locations";
import Matches from "../../pages/matches";
import AddPlayer from "../../pages/addPlayer";
import AddLocation from "../../pages/addLocation";
import AddMatch from "../../pages/addMatch";
import Statistics from "../../pages/statistics";


const AppRouter = () => {

    
    return (
        <Router>
            <Routes>
                { /* ROOT */ }
                <Route path="/" element={<Landing />} />

                { /* Home */ }
                <Route path="/home" element={<Guard><Home /></Guard>} />

                { /* Players */ }
                <Route path="/players" element={<Guard><Players /></Guard>} />
                <Route path="/players/add" element={<Guard><AddPlayer /></Guard>} />
                <Route path="/players/edit/:id" element={<Guard><AddPlayer /></Guard>} />

                { /* Locations */ }
                <Route path="/locations" element={<Guard><Locations /></Guard>} />
                <Route path="/locations/add" element={<Guard><AddLocation /></Guard>} />
                <Route path="/locations/edit/:id" element={<Guard><AddLocation /></Guard>} />

                { /* Matches */ }
                <Route path="/matches" element={<Guard><Matches /></Guard>} />
                <Route path="/matches/add" element={<Guard><AddMatch /></Guard>} />
                <Route path="/matches/edit/:id" element={<Guard><AddMatch /></Guard>} />

                { /* Statistics */}
                <Route path="/statistics" element={<Guard><Statistics /></Guard>} />

                { /* Auth */ }
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
            </Routes>
        </Router>
    );
};

export default AppRouter;
