import axios from 'axios';
import Domains from "../../config/domains";


const backendURI = Domains.backend;


export function createLocation(payload){
    return new Promise((resolve, reject) => {
        axios.post(`${backendURI}/locations/createLocation`, {payload})
        .then((res) => {
            resolve(res)
        }).catch((err) => console.error(err))
    })
}


export function getLocations(locationIds){
    return new Promise((resolve, reject) => {
        axios.get(`${backendURI}/locations/getLocations?locationIds=${locationIds.join(',')}`)
        .then((res) => {
            console.log(res);
            resolve(res?.data?.data?.locations)
        }).catch((err) => console.error(err))
    })
}


export const updateLocation = (payload, locationId) => {
    return new Promise((resolve, reject) => {
        axios.post(`${backendURI}/locations/updateLocation`, {payload, locationId})
        .then((res) => {
            resolve(res)
        }).catch((err) => console.error(err))
    })
}