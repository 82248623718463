import axios from 'axios';
import Domains from "../../config/domains";


const backendURI = Domains.backend;


export function createPlayer(payload){
    return new Promise((resolve, reject) => {
        axios.post(`${backendURI}/players/createPlayer`, {payload})
        .then((res) => {
            resolve(res)
        }).catch((err) => console.error(err))
    })
}

export function getPlayers(playerIds){
    return new Promise((resolve, reject) => {
        console.log(playerIds);
        axios.get(`${backendURI}/players/getPlayers?playerIds=${playerIds.join(',')}`)
        .then((res) => {
            console.log(res);
            resolve(res?.data?.data?.players)
        }).catch((err) => console.error(err))
    })
}


export const updatePlayer = (payload, playerId) => {
    return new Promise((resolve, reject) => {
        axios.post(`${backendURI}/players/updatePlayer`, {payload, playerId})
        .then((res) => {
            resolve(res)
        }).catch((err) => console.error(err))
    })
}