// Statistics.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PlayersContainer,
  PlayerItem,
  PlayerInfo,
  PlayerActions,
  AddPlayerButton,
  BackButton,
} from '../addPlayer/style.css'; // Adjust the import path
import './select.css';
import { getAnalytics, getLocations, getPlayers } from './utils';
import { TableBody, TableContainer, TableHead, TableTd, TableTh } from './style.css';

const Statistics = () => {
  const [data, setData] = useState({
    locations: [],
    players: [],
  });

  const { id } = useParams();

  const [disabled, setDisabled] = useState({
    locationId: false,
    firstPlayerId: false,
    secondPlayerId: false,
    firstPlayerScore: false,
    secondPlayerScore: false,
  });

  const navigate = useNavigate();

  const [newPlayer, setNewPlayer] = useState({
    locationId: '',
    firstPlayerId: '',
    secondPlayerId: '',
    firstPlayerScore: 0,
    secondPlayerScore: 0,
  });

  const [analytics, setAnalytics] = useState();

  const fetchAnalytics = () => {
    getAnalytics(newPlayer?.firstPlayerId, newPlayer?.secondPlayerId)
      .then((res) => {
        setAnalytics(res?.data?.data?.analytics);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    Promise.all([getLocations(), getPlayers()])
      .then(([locations, players]) => {
        setData({ ...data, locations, players });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  useEffect(() => {
    if (newPlayer.locationId) setDisabled({ ...disabled, locationId: true });
    if (newPlayer.firstPlayerId) setDisabled({ ...disabled, firstPlayerId: true });
    if (newPlayer.firstPlayerScore) setDisabled({ ...disabled, firstPlayerScore: true });
    if (newPlayer.secondPlayerId) setDisabled({ ...disabled, secondPlayerId: true });
    if (newPlayer.secondPlayerScore) setDisabled({ ...disabled, secondPlayerScore: true });
  }, [newPlayer]);

  return (
    <PlayersContainer>
      <h1>Statistics</h1>
      <PlayerItem>
        <PlayerInfo>
          <select
            value={newPlayer.firstPlayerId}
            onChange={(e) => setNewPlayer({ ...newPlayer, firstPlayerId: e.target.value })}
          >
            <option disabled={disabled.firstPlayerId} defaultValue>
              Select Player One
            </option>
            {data.players?.map((player) => (
              <option key={player._id} value={player._id}>
                {player.firstname} {player.lastname}
              </option>
            ))}
          </select>

          <select
            value={newPlayer.secondPlayerId}
            onChange={(e) => setNewPlayer({ ...newPlayer, secondPlayerId: e.target.value })}
          >
            <option disabled={disabled.secondPlayerId} defaultValue>
              Select Player Two
            </option>
            {data.players?.map((player) => (
              <option key={player._id} value={player._id}>
                {player.firstname} {player.lastname}
              </option>
            ))}
          </select>
        </PlayerInfo>
        <PlayerActions>
          <AddPlayerButton onClick={() => fetchAnalytics()}>Search</AddPlayerButton>
        </PlayerActions>
      </PlayerItem>

      {analytics?.playerOne && analytics?.playerTwo ? (
        <TableContainer>
          <TableHead>
            <tr>
              <TableTh></TableTh>
              <TableTh>Wins</TableTh>
              <TableTh>Ties</TableTh>
              <TableTh>Total Score</TableTh>
            </tr>
          </TableHead>
          <TableBody>
            <tr>
              <TableTd>{analytics?.playerOne?.name}</TableTd>
              <TableTd>{analytics?.playerOne?.wins}</TableTd>
              <TableTd>{analytics?.playerOne?.ties}</TableTd>
              <TableTd>{analytics?.playerOne?.total_score}</TableTd>
            </tr>
            <tr>
              <TableTd>{analytics?.playerTwo?.name}</TableTd>
              <TableTd>{analytics?.playerTwo?.wins}</TableTd>
              <TableTd>{analytics?.playerOne?.ties}</TableTd>
              <TableTd>{analytics?.playerTwo?.total_score}</TableTd>
            </tr>
          </TableBody>
        </TableContainer>
      ) : null}


      <BackButton onClick={() => navigate('/home')}>Back to Home</BackButton>
    </PlayersContainer>
  );
};

export default Statistics;
