import axios from 'axios';
import Domains from "../../config/domains";


const backendURI = Domains.backend;


export function createMatch(payload){
    return new Promise((resolve, reject) => {
        axios.post(`${backendURI}/matches/createMatch`, {payload})
        .then((res) => {
            resolve(res)
        }).catch((err) => console.error(err))
    })
}



export function getLocations(){
    return new Promise((resolve, reject) => {
        axios.get(`${backendURI}/locations/getLocations`)
        .then((res) => {
            resolve(res?.data?.data?.locations)
        }).catch((err) => console.error(err))
    })
}

export function getPlayers(){
    return new Promise((resolve, reject) => {
        axios.get(`${backendURI}/players/getPlayers`)
        .then((res) => {
            console.log(res);
            resolve(res?.data?.data?.players)
        }).catch((err) => console.error(err))
    })
}


export function getMatches(matchIds){
    return new Promise((resolve, reject) => {
        axios.get(`${backendURI}/matches/getMatches?matchIds=${matchIds.join(',')}`)
        .then((res) => {
            console.log(res);
            resolve(res?.data?.data?.matches)
        }).catch((err) => console.error(err))
    })
}


export const updateMatch = (payload, matchId) => {
    return new Promise((resolve, reject) => {
        axios.post(`${backendURI}/matches/updateMatch`, {payload, matchId})
        .then((res) => {
            resolve(res)
        }).catch((err) => console.error(err))
    })
}