import React from 'react';
import RegisterForm from './components/Form';

const Register = () => {

    return (
        <div style={{padding: "5rem"}}>
            <RegisterForm />
        </div>
    )
}

export default Register