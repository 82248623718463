import styled from 'styled-components';

export const LandingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    color: white; /* Text color */
`;

export const Nav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center items vertically */
    padding: 18px;
    width: 100%;
    background-color: #333; /* Background color for the navbar */
    position: absolute;
    top: 0;
`;

export const Ul = styled.ul`
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0; /* Remove the margin for the ul element */
`;

export const Li = styled.li`
    cursor: pointer;
`;

export const LandingContent = styled.div`
    text-align: center;
    padding: 20px;
`;

export const Section = styled.div`
    background-color: #444; /* Darker section background */
    padding: 20px;
    margin: 20px 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: white; /* Text color in sections */

    @media (max-width: 768px) {
        font-size: 16px; /* Adjust the font size for smaller screens */
    }
`;
